import { useState } from "react"
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { isBlank } from "../../utils/string"
import TipoAsignaturaSelector from "../../components/form/TipoAsignaturaSelector/TipoAsignaturaSelector"
import NivelAsignaturaSelector from "../../components/form/NivelAsignaturaSelector/NivelAsignaturaSelector"
import SubnivelAsignaturaSelector from "../../components/form/SubnivelAsignaturaSelector/SubnivelAsignaturaSelector"
import css from './ModalCrearAsignatura.module.css'

const ModalCrearAsignatura = ({ open, onSubmit, onClose }) => {

  const [asignatura, setAsignatura] = useState({
    nombre: '',
    nombre_completo: '',
    tipo: '',
    nivel: '',
    subnivel: '',
  })

  const isSubmitEnabled = (
    !isBlank(asignatura.nombre) && 
    !isBlank(asignatura.nombre_completo) &&
    !isBlank(asignatura.tipo) &&
    !isBlank(asignatura.nivel)
  )

  const handleSubmit = () => {
    onSubmit({ 
      nombre: asignatura.nombre,
			nombre_completo: asignatura.nombre_completo,
			descripcion: asignatura.nombre_completo,
			tipo: asignatura.tipo,
			nivel: asignatura.nivel,
      subnivel: asignatura.subnivel,
    })
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='md'>
      <DialogTitle>Añadir nueva asignatura</DialogTitle>
      <DialogContent className={css.main}>
        <DialogContentText className={css.intro}>
          Puedes añadir una nueva asignatura a <strong>mundo</strong>estudiante rellenando el siguiente formulario.
        </DialogContentText>
        <Alert className={css.warningBanner} severity="warning">
          Las asignaturas se cobrarán según el tipo de asignatura y el nivel de la misma, así como la contratación que
          se haga por parte del alumno y los distintos códigos promocionales que se utilicen.
        </Alert>
        <TextField
          className={css.input}
          label="Nombre"
          value={asignatura.nombre}
          onChange={e=> setAsignatura({ ...asignatura, nombre: e.target.value })}
          variant='standard'
        />
        <TextField
          className={css.input}
          label="Nombre Completo"
          value={asignatura.nombre_completo}
          onChange={e=> setAsignatura({ ...asignatura, nombre_completo: e.target.value })}
          variant='standard'
        />
        <TipoAsignaturaSelector
          className={css.input} 
          name='tipo'
          label='Tipo asignatura'
          value={asignatura.tipo}
          onChange={e=> setAsignatura({ ...asignatura, tipo: e.target.value })}
        />
        <NivelAsignaturaSelector
          className={css.input}
          name='nivel'
          label='Nivel asignatura'
          value={asignatura.nivel}
          onChange={e=> setAsignatura({ ...asignatura, nivel: e.target.value })}
        />
        <SubnivelAsignaturaSelector
          className={css.input}
          name='subnivel'
          label='Subnivel asignatura'
          value={asignatura.subnivel}
          onChange={e=> setAsignatura({ ...asignatura, subnivel: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button color='error' onClick={onClose}>Cancelar</Button>
        <Button disabled={!isSubmitEnabled} color='primary' onClick={handleSubmit}>Crear</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalCrearAsignatura