import React, { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Fab, IconButton } from "@mui/material"
import PageLoading from "../../../components/PageLoading/PageLoading"
import TableHeader from "../../../components/TableHeader/TableHeader"
import InformationTable from "../../../components/Table/InformationTable"
import { useNotification } from "../../../context/NotificationManager/NotificationManager"
import {
  createCodigoPromocionalDinamico,
  deleteCodigoPromocionalDinamico,
  getCodigosPromocionalesDinamicos,
  updateCodigoPromocionalDinamico,
} from "../../../utils/api/codigosPromocionales"
import { dateToString } from "../../../utils/date"
import ModalBorrarCodigoPromocional from "../../../modals/ModalBorrarCodigoPromocional/ModalBorrarCodigoPromocional"
import ModalEditarCodigoPromocional from "../../../modals/ModalEditarCodigoPromocional/ModalEditarCodigoPromocional"
import ModalCrearCodigoPromocional from "../../../modals/ModalCrearCodigoPromocional/ModalCrearCodigoPromocional"
import { textFilter } from "../../../utils/table"
import { downloadCSVFile, getCSVContent } from "../../../utils/csv"
import { formatForExport } from "../../../utils/math"
import css from "./CodigosPromocionales.module.css"

const CodigoPromocionalOptions = ({ codigo, onEdit, onDelete }) => {
  if (new Date(codigo.fechaFin) < new Date()) return null
  return (
    <>
      <IconButton size="small" color="secondary" onClick={() => onEdit(codigo)}>
        <i className="material-icons">edit</i>
      </IconButton>
      <IconButton size="small" color="error" onClick={() => onDelete(codigo)}>
        <i className="material-icons">delete</i>
      </IconButton>
    </>
  )
}

const CodigosPromocionales = () => {
  
  const notification = useNotification()
  const queryClient = useQueryClient()

  const [isEditModalOpen, setEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [isCreateModalOpen, setCreateModalOpen] = useState(false)

  const [codigoSeleccionado, setCodigoSeleccionado] = useState(null)

  const [search, setSearch] = useState('')

  const { data: codigosList = [], isLoading } = useQuery({
    queryKey: ["codigo-promocional-dinamico", "list"],
    queryFn: () => getCodigosPromocionalesDinamicos()
      .then((codigos) => codigos.sort((c1, c2)=> c1.fechaInicio - c2.fechaInicio))
      .catch((error) => {
        notification.error({ title: "Error al recuperar los códigos promocionales", content: error.message, })
        return []
      }),
  })

  const { isFetching: isCreatingCodigoPromocional, mutate: crearCodigoPromocional } = useMutation({
    mutationFn: createCodigoPromocionalDinamico,
    onSuccess: () => {
      setCreateModalOpen(false)
      notification.success({ title: "Código promocional creado", content: "Código promocional creado correctamente." })
      queryClient.invalidateQueries(["codigo-promocional-dinamico", "list"])
    },
    onError: err => notification.error({ title: "Error al crear el código promocional", content: err.message })
  })

  const { isFetching: isUpdatingCodigoPromocional, mutate: actualizarCodigoPromocional } = useMutation({
    mutationFn: updateCodigoPromocionalDinamico,
    onSuccess: () => {
      setEditModalOpen(false)
      notification.success({ title: "Código promocional actualizado", content: "Código promocional actualizado correctamente" })
      queryClient.invalidateQueries(["codigo-promocional-dinamico", "list"])
    },
    onError: (err) => notification.error({ title: "Error al actualizar el código promocional", content: err.message, }),
  })

  const { isFetching: isDeleteCodigoPromocional, mutate: borrarCodigoPromocional } = useMutation({
    mutationFn: deleteCodigoPromocionalDinamico,
    onSuccess: () => {
      setDeleteModalOpen(false)
      notification.success({ title: "Código promocional borrado", content: "El código promocional ha sido borrado correctamente" })
      queryClient.invalidateQueries(["codigo-promocional-dinamico", "list"])
    },
    onError: (err) => notification.error({ title: "Error al borrar el código promocional", content: err.message }),
  })

  const handleExport = ()=> {
    const content = getCSVContent({
      columns: ['Nombre', 'Descripcion', 'Tipo', 'Academias', 'Compatibilidad', 'Fecha Inicio', 'Fecha Fin', 'Descuento (%)', 'Descuento (€)', 'Horas Gratis'],
      data: codigosList,
      transform: p=> ([
        p.nombre,
        p.descripcion,
        p.tipo,
        p.centros.join(', '),
        p.compatibilidad.join(', '),
        dateToString(p.fechaInicio),
        dateToString(p.fechaFin),
        `${formatForExport(p.descuentoPorcentaje)}%`,
        `${formatForExport(p.descuentoFijo)}€`,
        `${p.horasGratis}h`,
      ])
    })
    downloadCSVFile(content, 'codigos-promocionales')
  }

  const filterSearch = codigo=> {
    return textFilter({ 
      object: codigo, 
      fields: ['nombre'],
      search: search
    })
  }

  const handleCreateCodigoPromocional = (codigoPromocional) => {
    if (isCreatingCodigoPromocional) return
    crearCodigoPromocional(codigoPromocional)
  }

  const handleEditarCodigoPromocional = (codigoPromocional) => {
    if (isUpdatingCodigoPromocional) return
    actualizarCodigoPromocional(codigoPromocional)
  }

  const handleDeleteCodigoPromocional = () => {
    if (isDeleteCodigoPromocional) return
    borrarCodigoPromocional(codigoSeleccionado.id)
  }

  const handleOpenCodigoEdit = (codigo)=> {
    setEditModalOpen(true)
    setCodigoSeleccionado(codigo)
  }

  const handleOpenCodigoDelete = (codigo)=> {
    setDeleteModalOpen(true)
    setCodigoSeleccionado(codigo)
  }

  const getPromocionDescription = (codigo) => {
    if (codigo.tipo === 'SEGUIMIENTO') return 'Seguimiento'
    if (codigo.tipo === 'DESCUENTO_PORCENTAJE') return `-${codigo.descuentoPorcentaje}%`
    if (codigo.tipo === 'DESCUENTO_PRECIO') return `-${codigo.descuentoFijo}€`
    if (codigo.tipo === 'HORAS_GRATIS') return `${codigo.horasGratis}h gratis`
    return 'Desconocido'
  }

  return (
    <PageLoading isLoading={isLoading}>
      <TableHeader
        title="Codigos Promocionales Dinámicos"
        actions={(
          <IconButton size='small' color='primary' onClick={handleExport}>
            <i className='material-icons'>download</i>
          </IconButton>
        )}
        search={search}
        onSearchChange={e=> setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: "Nombre", key: "nombre", align: "left" },
          { title: "Promoción", key: "promocion", align: "left" },
          { title: "Fechas", key: "fechas", sortDisabled: true, align: "left" },
          { title: "Opciones", key: "opciones", sortDisabled: true, align: "center", },
        ]}
        data={codigosList
          .filter(filterSearch)
          .sort((a, b) => new Date(b.fechaFin) - new Date(a.fechaFin))  
          .map((codigo) => ({
            nombre: codigo.nombre,
            promocion: getPromocionDescription(codigo),
            fechas: `${dateToString(codigo.fechaInicio)} → ${dateToString(codigo.fechaFin)}`,
            opciones: (
              <CodigoPromocionalOptions 
                codigo={codigo} 
                onEdit={handleOpenCodigoEdit} 
                onDelete={handleOpenCodigoDelete} 
              />
            ),
        }))}
      />
      <Fab
        className={css.nuevo}
        disabled={isCreatingCodigoPromocional}
        onClick={()=> setCreateModalOpen(true)}
        size="medium"
        color="primary" 
        aria-label="add"
      >
        <i className='material-icons'>add</i>
      </Fab>
      <ModalCrearCodigoPromocional
        open={isCreateModalOpen}
        onClose={()=> setCreateModalOpen(false)}
        onSubmit={handleCreateCodigoPromocional}
      />
      <ModalEditarCodigoPromocional
        open={isEditModalOpen}
        codigo={codigoSeleccionado}
        onClose={()=> setEditModalOpen(false)}
        onSubmit={handleEditarCodigoPromocional}
      />
      <ModalBorrarCodigoPromocional
        open={isDeleteModalOpen}
        onClose={()=> setDeleteModalOpen(false)}
        onSubmit={handleDeleteCodigoPromocional}
      />
    </PageLoading>
  )
}

export default CodigosPromocionales
