import { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { IconButton, Link, Typography } from "@mui/material"
import PageLoading from "../../../../components/PageLoading/PageLoading"
import TableHeader from "../../../../components/TableHeader/TableHeader"
import AcademiaSelector from "../../../../components/form/AcademiaSelector/AcademiaSelector"
import MesRecienteSelector from "../../../../components/form/MesRecienteSelector/MesRecienteSelector"
import InformationTable from "../../../../components/Table/InformationTable"
import { getAlumnosSeguimento } from "../../../../utils/api/alumnos"
import { useNotification } from "../../../../context/NotificationManager/NotificationManager"
import { textFilter } from "../../../../utils/table"
import { getAuth } from "../../../../utils/auth"
import { dateToString, monthToString } from "../../../../utils/date"
import { downloadCSVFile, getCSVContent } from "../../../../utils/csv"
import css from "./Alumnos.module.css"

const Status = ({ isComplete })=> {
  if (isComplete) return <i className={`material-icons ${css.iconCheck}`}>check</i>
  return <i className={`material-icons ${css.iconClose}`}>close</i>
}

const Alumnos = () => {
  const notification = useNotification()
  const [mes, setMes] = useState(monthToString(new Date()))
  const [centro, setCentro] = useState(getAuth().centro)
  const [search, setSearch] = useState("")

  const { isLoading: isLoadingAlumnos, data: alumnosList = [] } = useQuery({
    queryKey: ["alumno", "list", "new", mes, centro],
    queryFn: () => getAlumnosSeguimento({ mes, centro })
      .then((datos) => datos)
      .catch((err) => {
        notification.error({ title: "Error al recuperar los alumnos", content: err, }) 
        return []
      }),
  })

  const handleExport = () => {
    const content = getCSVContent({
      columns: [
        "Alumno",
        "Fecha Nacimiento",
        "Código postal",
        "Teléfono fijo",
        "Teléfono móvil",
        "Email",
        "Centro",
        "Tutor1",
        "Tutor2",
        "Facturación",
        "RGPD"
      ],
      data: alumnosList,
      transform: (alumno) => [
        alumno.nombre + " " + alumno.apellidos,
        dateToString(alumno.fechaNacimiento),
        alumno.codigoPostal,
        alumno.telefonoFijo || "-",
        alumno.telefonoMovil || "-",
        alumno.email,
        alumno.centro,
        alumno.tutor1 ? alumno.tutor1.nombre : "-",
        alumno.tutor2 ? alumno.tutor2.nombre : "-",
        alumno.facturacion ? "Sí" : "No",
        alumno.rgpdurl ? "Sí" : "No",
      ],
    })
    downloadCSVFile(content, "alumnos-mes")
  }

  const filterSearch = (alumno) => {
    return textFilter({
      object: alumno,
      fields: [ "nombre","apellidos"],
      search: search,
    })
  }

  const calcularProgreso = (alumno) => {
    const criteriosCompletos = [
      alumno.fechaNacimiento,
      alumno.dni,
      alumno.codigoPostal,
      alumno.email,
      alumno.telefonoFijo || alumno.telefonoMovil,
      alumno.facturacion?.identificacion && alumno.facturacion?.nombre,
      alumno.condicionesUrl,
      alumno.rgpdurl,
    ].filter(Boolean).length
    return Math.round((criteriosCompletos / 8) * 100)
  }

  
  const determinarEstado = (progreso) => {
    if (progreso < 50) return "ko"
    if (progreso < 85) return "warning"
    return "ok"
  }

  return (
    <PageLoading isLoading={isLoadingAlumnos}>
      <TableHeader
        actions={
          <div className={css.actions}>
            <IconButton
              className={css.download}
              size="small"
              color="primary"
              onClick={handleExport}
            >
              <i className="material-icons">download</i>
            </IconButton>
            <AcademiaSelector
              name="academia"
              label="Academia"
              value={centro}
              onChange={(e) => setCentro(e.target.value)}
            />
            <MesRecienteSelector
              className={css.mes}
              name="mes"
              label="Mes"
              value={mes}
              onChange={(e) => setMes(e.target.value)}
            />
          </div>
        }
        title="Alumnos"
        search={search}
        onSearchChange={(e) => setSearch(e.target.value)}
      />
      <InformationTable
        details={[
          { title: "Alumno", key: "alumno", align: "left" },
          { title: "Fecha nacimiento", key: "fechaNacimiento", sortDisabled: true, align: "center" },
          { title: "Teléfono", key: "telefono", sortDisabled: true, align: "center" },
          { title: "DNI", key: "dni", sortDisabled: true, align: "center" },
          { title: "Email", key: "email", sortDisabled: true, align: "center" },
          { title: "Codigo Postal", key: "codigoPostal", sortDisabled: true, align: "center" },
          { title: "Facturación", key: "facturacion", sortDisabled: true, align: "center" },
          { title: "Condiciones", key: "condiciones", sortDisabled: true, align: "center" },
          { title: "RGPD", key: "rgpd", sortDisabled: true, align: "center" },
          { title: "Progreso", key: "progreso", sortDisabled: true, align: "center" },
        ]}
        data={alumnosList.filter(filterSearch).map((alumno) => {
          const progreso = calcularProgreso(alumno)
          const status = determinarEstado(progreso)
          return {
            alumno: (
              <Link className={css.link} href={`https://agora.mundoestudiante.cloud/alumnos/${alumno.id}/ficha`} target="_blank">
                {alumno.nombre + " " + alumno.apellidos}
              </Link>
            ),
            fechaNacimiento: <Status isComplete={alumno.fechaNacimiento} />,
            telefono: <Status isComplete={alumno.telefonoFijo || alumno.telefonoMovil} />,
            dni: <Status isComplete={alumno.dni} />,
            email: <Status isComplete={alumno.email} />,
            codigoPostal: <Status isComplete={alumno.codigoPostal} />,
            facturacion: <Status isComplete={alumno.facturacion} />,
            condiciones: <Status isComplete={alumno.condicionesUrl} />,
            rgpd: <Status isComplete={alumno.rgpdurl} />,
            progreso: <Typography className={css.progress} data-status={status}>{progreso}%</Typography>,
          }
        })}
      />
    </PageLoading>
  )
}

export default Alumnos
