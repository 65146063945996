import { useState } from 'react'
import { Route, Routes } from 'react-router'
import Header from '../Header/Header'
import SideMenu from '../SideMenu/SideMenu'

import Content from '../Content/Content'
import Help from '../Help/Help'

import Dashboard from '../../views/Dashboard/Dashboard'

import MarketingAdquisicionCaptacionCentro from '../../views/Marketing/Adquisicion/CaptacionCentro/CaptacionCentro'
import MarketingAdquisicionCaptacionMes from '../../views/Marketing/Adquisicion/CaptacionMes/CaptacionMes'
import MarketingAdquisicionDetallesLeads from '../../views/Marketing/Adquisicion/DetallesLeads/DetallesLeads'

import MarketingGestionLeads from '../../views/Marketing/Gestion/Leads/Leads'
import MarketingGestionAlumnos from '../../views/Marketing/Gestion/Alumnos/Alumnos'
import MarketingGestionTelefonosEntrantes from '../../views/Marketing/Gestion/TelefonosEntrantes/TelefonosEntrantes'

import MarketingComportamientoAnalisis from '../../views/Marketing/Comportamiento/Analisis/Analisis'
import MarketingComportamientoAsignaturas from '../../views/Marketing/Comportamiento/Asignaturas/Asignaturas'
import MarketingComportamientoLeadEntrantes from '../../views/Marketing/Comportamiento/LeadEntrantes/LeadEntrantes'
import MarketingComportamientoCodigos from '../../views/Marketing/Comportamiento/Codigos/Codigos'

import ContabilidadVentasCentro from '../../views/Contabilidad/Ventas/Centro/Centro'
import ContabilidadVentasMes from '../../views/Contabilidad/Ventas/Mes/Mes'
import ContabilidadFacturas from '../../views/Contabilidad/Facturas/Facturas'
import ContabilidadCajas from '../../views/Contabilidad/Cajas/Cajas'
import ContabilidadPagosPendientes from '../../views/Contabilidad/Pagos/Pendientes/Pendientes'
import ContabilidadPagosModificaciones from '../../views/Contabilidad/Pagos/Modificaciones/Modificaciones'
import ContabilidadPagosModificar from '../../views/Contabilidad/Pagos/Modificar/Modificar'
import ContabilidadPagosIngresos from '../../views/Contabilidad/Pagos/Ingresos/Ingresos'
import ContabilidadContratacionesMensuales from '../../views/Contabilidad/Contrataciones/Contrataciones'

import AdministracionAcademias from '../../views/Administracion/Academias/Academias'
import AdministracionCalendario from '../../views/Administracion/Calendario/Calendario'
import AdministracionAsignaturas from '../../views/Administracion/Asignaturas/Asignaturas'
import AdministracionCentrosEstudio from '../../views/Administracion/CentrosEstudio/CentrosEstudio'
import AdministracionHelpdesk from '../../views/Administracion/Helpdesk/Helpdesk'
import AdministracionCertificados from '../../views/Administracion/Certificados/Certificados'
import AdministracionDocumentos from '../../views/Administracion/Documentos/Documentos'
import AdministracionCodigosPromocionales from '../../views/Administracion/CodigosPromocionales/CodigosPromocionales'
import AdministracionFormaciones from '../../views/Administracion/Formaciones/Formaciones'

import AdministracionHorariosAperturas from '../../views/Administracion/Horarios/Aperturas/Aperturas'
import AdministracionHorariosClases from '../../views/Administracion/Horarios/Clases/Clases'
import AdministracionHorariosProfesores from '../../views/Administracion/Horarios/Profesores/Profesores'

import PersonalAdministracion from '../../views/Personal/Administracion/Administracion'
import PersonalProfesorado from '../../views/Personal/Profesorado/Profesorado'
import PersonalLogs from '../../views/Personal/Logs/Logs'



const Layout = ()=> {

  const [help, setHelp] = useState(false)

  return (
    <>
      <Header onHelpClick={()=> setHelp(true)} />
      <SideMenu />
      <Content>
        <Routes>

          <Route path='/marketing/adquisicion/captacion-centro' element={<MarketingAdquisicionCaptacionCentro />} />
          <Route path='/marketing/adquisicion/captacion-mes' element={<MarketingAdquisicionCaptacionMes />} />
          <Route path='/marketing/adquisicion/leads' element={<MarketingAdquisicionDetallesLeads />} />
          <Route path='/marketing/gestion/leads' element={<MarketingGestionLeads />} />
          <Route path='/marketing/gestion/alumnos' element={<MarketingGestionAlumnos />} />
          <Route path='/marketing/gestion/telefonos-entrantes' element={<MarketingGestionTelefonosEntrantes />} />
          <Route path='/marketing/comportamiento/asignaturas' element={<MarketingComportamientoAsignaturas />} />
          <Route path='/marketing/comportamiento/analisis' element={<MarketingComportamientoAnalisis />} />
          <Route path='/marketing/comportamiento/lead-entrantes' element={<MarketingComportamientoLeadEntrantes/>} />
          <Route path='/marketing/comportamiento/codigos' element={<MarketingComportamientoCodigos />} />

          <Route path='/contabilidad/ventas/centro' element={<ContabilidadVentasCentro />} />          
          <Route path='/contabilidad/ventas/mes' element={<ContabilidadVentasMes />} />
          <Route path='/contabilidad/facturas' element={<ContabilidadFacturas />} />
          <Route path='/contabilidad/cajas' element={<ContabilidadCajas />} />
          <Route path='/contabilidad/pagos/pendientes' element={<ContabilidadPagosPendientes />} />
          <Route path='/contabilidad/pagos/modificaciones' element={<ContabilidadPagosModificaciones />} />
          <Route path='/contabilidad/pagos/modificar' element={<ContabilidadPagosModificar />} />
          <Route path='/contabilidad/pagos/ingresos' element={<ContabilidadPagosIngresos />} />
          <Route path='/contabilidad/contrataciones-mensuales' element={< ContabilidadContratacionesMensuales />} />

          <Route path='/administracion/calendario' element={<AdministracionCalendario />} />
          <Route path='/administracion/academias' element={<AdministracionAcademias />} />
          <Route path='/administracion/asignaturas' element={<AdministracionAsignaturas />} />
          <Route path='/administracion/centros-estudio' element={<AdministracionCentrosEstudio />} />
          <Route path='/administracion/helpdesk' element={<AdministracionHelpdesk />} />
          <Route path='/administracion/certificados' element={<AdministracionCertificados />} />
          <Route path='/administracion/documentos' element={<AdministracionDocumentos />} />
          <Route path='/administracion/horarios/aperturas' element={<AdministracionHorariosAperturas />} />
          <Route path='/administracion/horarios/clases' element={<AdministracionHorariosClases />} />
          <Route path='/administracion/horarios/profesores' element={<AdministracionHorariosProfesores />} />
          <Route path='/administracion/codigos-promocionales' element={<AdministracionCodigosPromocionales/>} />
          <Route path='/administracion/formaciones' element={<AdministracionFormaciones/>} />

          <Route path='/personal/administracion' element={<PersonalAdministracion />} />
          <Route path='/personal/profesorado' element={<PersonalProfesorado />} />
          <Route path='/personal/logs' element={<PersonalLogs />} />
          
          <Route path='/' element={<Dashboard />} />
          
        </Routes>
      </Content>
      <Help open={help} onClose={()=> setHelp(false)} />
    </>
  )
}

export default Layout