import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import css from './NivelAsignaturaSelector.module.css'

const NIVELES = [
  'PRIMARIA',
	'SECUNDARIA',
	'BACHILLERATO',
	'UNIVERSIDAD',

  'FP',

  'IDIOMA',
	'OTRAS', 
]

const NivelAsignaturaSelector = ({ className, name, label, value, onChange })=> (
  <FormControl className={`${className} ${css.main}`} variant="standard">
    <InputLabel id={name}>{label}</InputLabel>
    <Select
      labelId={name}
      id={name}
      value={value}
      onChange={onChange}
      label={label}
    >
      {NIVELES.map(nivel=> <MenuItem key={nivel} value={nivel}>{nivel.replaceAll('_', ' ')}</MenuItem> )}
    </Select>
  </FormControl>
)

export default NivelAsignaturaSelector

