import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import css from './TipoCodigoPromocionalSelector.module.css'

const TIPOS_DE_DESCUENTO = [
  { value: 'SEGUIMIENTO', label: 'Seguimiento' },
  { value: 'DESCUENTO_PRECIO', label: 'Descuento de precio' },
  { value: 'DESCUENTO_PORCENTAJE', label: 'Descuento porcentaje' },
  { value: 'HORAS_GRATIS', label: 'Horas gratis' },
]

const TipoCodigoPromocionalSelector = ({ 
  className='', 
  name, 
  label, 
  value='', 
  disabled = false,
  onChange 
}) => (
  <FormControl className={`${className} ${css.main}`} variant="standard">
    <InputLabel id={name}>{label}</InputLabel>
    <Select
      labelId={name}
      name={name} 
      id={name}
      value={value}
      disabled={disabled}
      onChange={onChange}
      label={label}
    >
      {TIPOS_DE_DESCUENTO.map(tipo => (
        <MenuItem key={tipo.value} value={tipo.value}>
          {tipo.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default TipoCodigoPromocionalSelector
